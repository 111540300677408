import { apiSlice } from "../Slices/apiSlice";

export const RequestService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRequests: builder.query({
      query: () => "/reqs",
      providesTags: ["req"],
    }),

    getAllRequestsStatus: builder.query({
      query: () => "/req-status",
      providesTags: ["req"],
    }),

    getRequestsFilterByTime: builder.query({
      query: () => `reqs/filterByTime`,
      providesTags: ["req"],
    }),    
    
    getRequestsByStatus: builder.query({
      query: (reqStatus) => `reqs/byReqStatues/${reqStatus}`,
      providesTags: ["req"],
    }),

    getRequestById: builder.query({
      query: (reqId) => `reqs/${reqId}`,
      providesTags: ["req"],
    }),

    createRequest: builder.mutation({
      query: (Details) => ({
        url: `/reqs`,
        method: "POST",
        body: Details,
      }),
      invalidatesTags: ["req"],
    }),

    updateRequest: builder.mutation({
      query: (Details) => ({
        url: `/reqs/${Details.reqId}`,
        method: "PUT",
        body: Details,
      }),
      invalidatesTags: ["req"],
    }),

    updateRequestStatus: builder.mutation({
      query: (Details) => ({
        url: `reqs/admin/update-req-stat`,
        method: "PUT",
        body: Details,
      }),
      invalidatesTags: ["req"],
    }),

    deleteRequest: builder.mutation({
      query: (id) => ({
        url: `reqs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["req"],
    }),

    updateReqResource: builder.mutation({
      query: (Details) => ({
        url: `/reqs/update-resource`,
        method: "PUT",
        body: Details,
      }),
      invalidatesTags: ["req"],
    }),

  }),
});

export const {
  useGetAllRequestsQuery,
  useGetAllRequestsStatusQuery,
  useGetRequestsFilterByTimeQuery,
  useGetRequestsByStatusQuery,
  useGetRequestByIdQuery,
  useCreateRequestMutation,
  useUpdateRequestMutation,
  useDeleteRequestMutation,
  useUpdateRequestStatusMutation,
  useUpdateReqResourceMutation,
} = RequestService;
