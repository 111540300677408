// RequestList.jsx
import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { PaginationDetails } from "../../Utils/UtilsReq";
import styles from "./RequestList.module.css";
import RequestDetailsDialog from "./RequestDetailsDialog";
import CardText from "./CardText";
import RequestApproveDialog from "./RequestApproveDialog";
import RequestRejectDialog from "./RequestRejectDialog";
import RequestStartDialog from "./RequestStartDialog";
import RequestPauseDialog from "./RequestpauseDialog";
import RequestCloseDialog from "./RequestCloseDialog";
import RequestCompleteDialog from "./RequestCompleteDialog";

const RequestList = ({ data, status }) => {
  const dataSet = Array.isArray(data) ? data : [];
  const { page, count, handleChange, _DATA } = PaginationDetails(dataSet);
  const [selectedReqId, setSelectedReqId] = useState(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isStartDialogOpen, setIsStartDialogOpen] = useState(false);
  const [ispauseDialogOpen, setIspauseDialogOpen] = useState(false);
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);

  const roles = sessionStorage.getItem("roles");

  const handleClick = (reqId) => {
    setSelectedReqId(reqId);
    setIsDialogOpen(true);
  };

  const handleApproveClick = (reqId) => {
    setSelectedReqId(reqId);
    setIsApproveDialogOpen(true);
  };

  const handleRejectClick = (reqId) => {
    setSelectedReqId(reqId);
    setIsRejectDialogOpen(true);
  };
  const handleStartClick = (reqId) => {
    setSelectedReqId(reqId);
    setIsStartDialogOpen(true);
  };

  const handlePauseClick = (reqId) => {
    setSelectedReqId(reqId);
    setIspauseDialogOpen(true);
  };

  const handleCloseClick = (reqId) => {
    setSelectedReqId(reqId);
    setIsCloseDialogOpen(true);
  };

  const handleCompleteClick = (reqId) => {
    setSelectedReqId(reqId);
    setIsCompleteDialogOpen(true);
  };

  return (
    <>
      <div className={styles.container}>
        {_DATA.currentData()?.map((request) => (
          <Card key={request.reqId} className={styles.card}>
            <CardContent>
              <CardText request={request}/>

              <div className={styles.buttonContainer}>
                <div
                  className={styles.verifyButton}
                  style={{
                    color: "#5A9BD8",
                    borderColor: "#5A9BD8",
                    border: "1px solid #5A9BD8",
                    padding: "6px 12px",
                    borderRadius: "4px",
                    display: "inline-block",
                    cursor: 'pointer',
                  }}
                  onClick={() => handleClick(request.reqId)}
                >
                  View Request
                </div>

                <div className={styles.rightButtonContainer}>
                  {status === "SUBMITED" || status === "APPROVED BY RESOUCE" && roles?.split(",").includes("PRIV_ES_EDIT_REQUEST") ?  (<Button
                    variant="contained"
                    className={styles.completeButton}
                    onClick={() => handleApproveClick(request.reqId)}
                  >
                    Approve
                  </Button>) : null}
                  {status === "SUBMITED"|| status === "APPROVED BY RESOUCE" && roles?.split(",").includes("PRIV_ES_EDIT_REQUEST") ?  (<Button
                    variant="contained"
                    className={styles.completeButton}
                    onClick={() => handleRejectClick(request.reqId)}
                  >
                    Reject
                  </Button>) : null}
                  {status === "APPROVED BY ADMIN" && <Button
                    variant="contained"
                    className={styles.completeButton}
                    onClick={() => handleStartClick(request.reqId)}
                  >
                    Start
                  </Button>}
                  {status === "APPROVED BY ADMIN" || status === "STARTED" && roles?.split(",").includes("PRIV_ES_EDIT_REQUEST") ?  (<Button
                    variant="contained"
                    className={styles.completeButton}
                    onClick={() => handlePauseClick(request.reqId)}
                  >
                    Pause
                  </Button>) : null}
                  {status === "REJECTED BY ADMIN" || status === "REJECTED BY RESOURCE" || status === "COMPLETED" && roles?.split(",").includes("PRIV_ES_EDIT_REQUEST") ? <Button
                    variant="contained"
                    className={styles.completeButton}
                    onClick={() => handleCloseClick(request.reqId)}
                  >
                    Close
                  </Button>: null}
                    { status === "STARTED"|| status === "PAUSED" && roles?.split(",").includes("PRIV_ES_EDIT_REQUEST") ? <Button
                      variant="contained"
                      className={styles.completeButton}
                      onClick={() => handleCompleteClick(request.reqId)}
                    >
                      Complete
                      </Button> : null}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      <Box my={2} display="flex" justifyContent="center">
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          color="primary"
          onChange={handleChange}
          className={styles.pagination}
        />
        {isDialogOpen && (
          <RequestDetailsDialog
            onClose={() => setIsDialogOpen(false)}
            reqId={selectedReqId}
          />
        )}
        {isApproveDialogOpen && (
          <RequestApproveDialog
            onClose={() => setIsApproveDialogOpen(false)}
            reqId={selectedReqId}
          />
        )}
        {isRejectDialogOpen && (
          <RequestRejectDialog
            onClose={() => setIsRejectDialogOpen(false)}
            reqId={selectedReqId}
          />
        )}
        {isStartDialogOpen && (
          <RequestStartDialog
            onClose={() => setIsStartDialogOpen(false)}
            reqId={selectedReqId}
          />
        )}
        {ispauseDialogOpen && roles?.split(",").includes("PRIV_ES_EDIT_REQUEST") ? (
          <RequestPauseDialog
            onClose={() => setIspauseDialogOpen(false)}
            reqId={selectedReqId}
          />
        ) : null }
        {isCloseDialogOpen && (
          <RequestCloseDialog
            onClose={() => setIsCloseDialogOpen(false)}
            reqId={selectedReqId}
          />
        )}
        {isCompleteDialogOpen && (
          <RequestCompleteDialog
            onClose={() => setIsCompleteDialogOpen(false)}
            reqId={selectedReqId}
          />
        )}
      </Box>
    </>
  );
};

export default RequestList;
