import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { toast } from "react-toastify";
import styles from "./Request.module.css";
import { useLazyGetResourceByAreaDateTimeQuery } from "../../Store/Services/ResourceService";
import { useUpdateReqResourceMutation } from "../../Store/Services/RequestService";
const schema = yup.object({
  resourceId: yup.string().required("Resource is required"),
});

const UpdateResource = ({ open, onClose, resourceId, reqId, calId, slotId, areaId, catId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [updateResource] = useUpdateReqResourceMutation();
  const [triggerResourceQuery, { data: resources, isLoading, isError }] =
    useLazyGetResourceByAreaDateTimeQuery();

  const [selectedResource, setSelectedResource] = useState("");

  useEffect(() => {
    if (calId && slotId && areaId && catId) {
      const queryParams = { calId, slotId, areaId, catId };
      console.log("Triggering API call with parameters:", queryParams);
      triggerResourceQuery(queryParams);
    }
  }, [calId, slotId, areaId, catId, triggerResourceQuery]);

  useEffect(() => {
    if (resources && resourceId) {
      const foundResource = resources.find((resource) => resource.resourceId === resourceId);
      if (foundResource) {
        setSelectedResource(foundResource.resourceId);
      }
    }
  }, [resources, resourceId]);

  const submit = async (data) => {
    try {
      await updateResource({ reqId, resourceId: data.resourceId }).unwrap(); 
      toast.success("Resource added successfully!", { position: "bottom-right" });
      onClose();
    } catch (err) {
      console.error("Error while updating resource:", err);
      // toast.error("Error, something went wrong", { position: "bottom-right" });
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <div className={styles.headingContainer}>
          <div className={styles.dialogHeader}>
            <Typography fontWeight={600} className={styles.dialogTitle} variant="h6">
              Add Resource
            </Typography>
          </div>
          <IconButton onClick={onClose} className={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submit)} autoComplete="off">
          <div className={styles.formItem}>
            <label className={styles.formLabel}>Resource:</label>
            {errors.resourceId && (
              <span className={styles.validation}>* {errors.resourceId.message}</span>
            )}
            <Select
              {...register("resourceId")}
              value={selectedResource}
              onChange={(e) => setSelectedResource(e.target.value)}
              className={styles.formInput}
              size="small"
              fullWidth
              displayEmpty
              renderValue={(value) => {
                if (!value) {
                  return resources && resources.length > 0
                    ? "Select New Resource"
                    : "No Resources Available";
                }
                const selected = resources?.find((resource) => resource.resourceId === value);
                return selected ? selected.name : "";
              }}
            >
              {resources &&
                resources.map((resource) => (
                  <MenuItem key={resource.resourceId} value={resource.resourceId}>
                    {resource.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              className={styles.submitButton}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateResource;
